const LiveWall = ({ options }) => {
    return (
        <div style={{ display: 'block', textAlign: 'center', marginTop: '1.25rem' }}>
            <a href={`https://greeniuz-panelconfig-acc.lwdev.nl?postal=${options.api.PostCode}&housenr=${options.api.HouseNumber}${options.api.HouseAddition ? `&housenrAdd=${options.api.HouseAddition}` : ''}`} rel="noreferrer" target="_blank">
                LiveWall
            </a>
        </div>
    );
};

export default LiveWall;
