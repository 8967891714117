import ReactLoading from 'react-loading';

const Loading = () => {
    return (
        <div className="loading">
            <ReactLoading type="spinningBubbles" color="#0EE0B8" height={50} width={50} className="loading-icon" />
        </div>
    );
};

export default Loading;
