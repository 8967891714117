import { useState } from 'react';
import Invalid from './Invalid';
import Inactive from './Inactive';
import InactiveGlass from './InactiveGlass';
import { targetTemp, floorInsideAdjacentTemp, floorOutsideAdjacentTemp, ceilingInsideAdjacentTemp, ceilingOutsideAdjacentTemp, wallsInsideAdjacentTemp, wallsOutsideAdjacentTemp, extraRequiredWattage, roundOffPanelsPerRoom, panelWattage, panelCosts, domoticaPercentage, domoticaCosts, vat, gasForHeating, heatingDays, heatingHours } from '../utils/variables';
import LiveWall from './LiveWall';

const Result = ({ options }) => {
    const [showForm, setShowForm] = useState(false);
    // const [isSent, setSent] = useState({ send: false, message: '' });
    const [isLoading, setLoading] = useState(false);
    // const [hasError, setError] = useState(null);
    const [checked, setChecked] = useState(false);

    const handleShowForm = (e) => {
        setShowForm(!showForm);
    };

    const [formData, updateFormData] = useState({});

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };
    const handleChecked = (e) => {
        setChecked(!checked);
    };

    const sendForm = (event, payload) => {
        event.preventDefault();

        setLoading(true);
        // setError(null);

        fetch('https:///greeniuz.nl/wp-json/contact-form-7/v1/contact-forms/1455/feedback', {
            method: 'POST',
            body: formdata,
        })
            .then((resp) => resp.json())
            .then((resp) => {
                if (resp.status !== 'mail_sent') throw resp.message;
                // setSent({ send: true, message: resp.message });
                window.location.assign('https://meet.greeniuz.nl/book/?firstName=' + formData.firstname + '&lastName=' + formData.surname + '&email=' + formData.mail + '');
            })
            .catch((error) => {
                // setSent({ send: false });
                // setError(error);
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const currencyFormat = new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 0,
    });

    const handleParse = (e) => {
        return parseFloat(`${e}`.replace(',', '.'));
    };

    const glassPercentage = (() => {
        if (options.api.HouseType === 'Vrijstaande woning') return 0.25;
        else if (options.api.HouseType === '2 onder 1 kap woning' || options.api.HouseType === 'Geschakelde 2 onder 1 kapwoning' || options.api.HouseType === 'Meergezinswoning') return 0.172;
        else if (options.api.HouseType === 'Geschakelde woning' || options.api.HouseType === 'Tussen/rijwoning' || options.api.HouseType === 'Eengezinswoning') return 0.172;
        else if (options.api.HouseType === 'Hoekwoning' || 'Eindwoning') return 0.172;
        else if (options.api.HouseType === 'Galerijflat' || options.api.HouseType === 'Portiekflat' || options.api.HouseType === 'Corridorflat' || options.api.HouseType === 'Maisonnette' || options.api.HouseType === 'Benedenwoning' || options.api.HouseType === 'Bovenwoning' || options.api.HouseType === 'Portiekwoning') return 0.256;
    })();

    const floorInsideCount = (() => {
        if (options.api.HouseType === 'Vrijstaande woning') return 0;
        else if (options.api.HouseType === '2 onder 1 kap woning' || options.api.HouseType === 'Geschakelde 2 onder 1 kapwoning' || options.api.HouseType === 'Meergezinswoning') return 0;
        else if (options.api.HouseType === 'Geschakelde woning' || options.api.HouseType === 'Tussen/rijwoning' || options.api.HouseType === 'Eengezinswoning') return 0;
        else if (options.api.HouseType === 'Hoekwoning' || options.api.HouseType === 'Eindwoning') return 0;
        else if (options.api.HouseType === 'Galerijflat' || options.api.HouseType === 'Portiekflat' || options.api.HouseType === 'Corridorflat' || options.api.HouseType === 'Maisonnette' || options.api.HouseType === 'Benedenwoning' || options.api.HouseType === 'Bovenwoning' || options.api.HouseType === 'Portiekwoning') return 1;
    })();

    const floorOutsideCount = (() => {
        if (options.api.HouseType === 'Vrijstaande woning') return 1;
        else if (options.api.HouseType === '2 onder 1 kap woning' || options.api.HouseType === 'Geschakelde 2 onder 1 kapwoning' || options.api.HouseType === 'Meergezinswoning') return 1;
        else if (options.api.HouseType === 'Geschakelde woning' || options.api.HouseType === 'Tussen/rijwoning' || options.api.HouseType === 'Eengezinswoning') return 1;
        else if (options.api.HouseType === 'Hoekwoning' || options.api.HouseType === 'Eindwoning') return 1;
        else if (options.api.HouseType === 'Galerijflat' || options.api.HouseType === 'Portiekflat' || options.api.HouseType === 'Corridorflat' || options.api.HouseType === 'Maisonnette' || options.api.HouseType === 'Benedenwoning' || options.api.HouseType === 'Bovenwoning' || options.api.HouseType === 'Portiekwoning') return 0;
    })();

    const ceilingInsideCount = (() => {
        if (options.api.HouseType === 'Vrijstaande woning') return 0;
        else if (options.api.HouseType === '2 onder 1 kap woning' || options.api.HouseType === 'Geschakelde 2 onder 1 kapwoning' || options.api.HouseType === 'Meergezinswoning') return 0;
        else if (options.api.HouseType === 'Geschakelde woning' || options.api.HouseType === 'Tussen/rijwoning' || options.api.HouseType === 'Eengezinswoning') return 0;
        else if (options.api.HouseType === 'Hoekwoning' || options.api.HouseType === 'Eindwoning') return 0;
        else if (options.api.HouseType === 'Galerijflat' || options.api.HouseType === 'Portiekflat' || options.api.HouseType === 'Corridorflat' || options.api.HouseType === 'Maisonnette' || options.api.HouseType === 'Benedenwoning' || options.api.HouseType === 'Bovenwoning' || options.api.HouseType === 'Portiekwoning') return 1;
    })();

    const ceilingOutsideCount = (() => {
        if (options.api.HouseType === 'Vrijstaande woning') return 1;
        else if (options.api.HouseType === '2 onder 1 kap woning' || options.api.HouseType === 'Geschakelde 2 onder 1 kapwoning' || options.api.HouseType === 'Meergezinswoning') return 1;
        else if (options.api.HouseType === 'Geschakelde woning' || options.api.HouseType === 'Tussen/rijwoning' || options.api.HouseType === 'Eengezinswoning') return 1;
        else if (options.api.HouseType === 'Hoekwoning' || options.api.HouseType === 'Eindwoning') return 1;
        else if (options.api.HouseType === 'Galerijflat' || options.api.HouseType === 'Portiekflat' || options.api.HouseType === 'Corridorflat' || options.api.HouseType === 'Maisonnette' || options.api.HouseType === 'Benedenwoning' || options.api.HouseType === 'Bovenwoning' || options.api.HouseType === 'Portiekwoning') return 0;
    })();

    const wallsInsideCount = (() => {
        if (options.api.HouseType === 'Vrijstaande woning') return 0;
        else if (options.api.HouseType === '2 onder 1 kap woning' || options.api.HouseType === 'Geschakelde 2 onder 1 kapwoning' || options.api.HouseType === 'Meergezinswoning') return 0;
        else if (options.api.HouseType === 'Geschakelde woning' || options.api.HouseType === 'Tussen/rijwoning' || options.api.HouseType === 'Eengezinswoning') return 2;
        else if (options.api.HouseType === 'Hoekwoning' || options.api.HouseType === 'Eindwoning') return 1;
        else if (options.api.HouseType === 'Galerijflat' || options.api.HouseType === 'Portiekflat' || options.api.HouseType === 'Corridorflat' || options.api.HouseType === 'Maisonnette' || options.api.HouseType === 'Benedenwoning' || options.api.HouseType === 'Bovenwoning' || options.api.HouseType === 'Portiekwoning') return 2;
    })();

    const wallsOutsideCount = (() => {
        if (options.api.HouseType === 'Vrijstaande woning') return 4;
        else if (options.api.HouseType === '2 onder 1 kap woning' || options.api.HouseType === 'Geschakelde 2 onder 1 kapwoning' || options.api.HouseType === 'Meergezinswoning') return 4;
        else if (options.api.HouseType === 'Geschakelde woning' || options.api.HouseType === 'Tussen/rijwoning' || options.api.HouseType === 'Eengezinswoning') return 2;
        else if (options.api.HouseType === 'Hoekwoning' || 'Eindwoning') return 3;
        else if (options.api.HouseType === 'Galerijflat' || options.api.HouseType === 'Portiekflat' || options.api.HouseType === 'Corridorflat' || options.api.HouseType === 'Maisonnette' || options.api.HouseType === 'Benedenwoning' || options.api.HouseType === 'Bovenwoning' || options.api.HouseType === 'Portiekwoning') return 2;
    })();

    let isolationFloor = 0;
    if (options.isolationFloor === 'yes') {
        isolationFloor = (() => {
            if (options.api.BuildYear < 1950) return 2;
            else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980) return 2;
            else if (options.api.BuildYear > 1980 && options.api.BuildYear < 1992) return 0;
            else if (options.api.BuildYear > 1992 && options.api.BuildYear < 2012) return 0;
            else if (options.api.BuildYear > 2012 && options.api.BuildYear < 2015) return 0;
            else if (options.api.BuildYear > 2015) return 0;
        })();
    }

    let isolationWalls = 0;
    if (options.isolationWalls === 'yes') {
        isolationWalls = (() => {
            if (options.api.BuildYear < 1950) return 1.5;
            else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980) return 1.5;
            else if (options.api.BuildYear > 1980 && options.api.BuildYear < 1992) return 0;
            else if (options.api.BuildYear > 1992 && options.api.BuildYear < 2012) return 0;
            else if (options.api.BuildYear > 2012 && options.api.BuildYear < 2015) return 0;
            else if (options.api.BuildYear > 2015) return 0;
        })();
    }

    const rcFloorsPre = (() => {
        if (options.api.BuildYear < 1950) return 0.5;
        else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980) return 0.5;
        else if (options.api.BuildYear > 1980 && options.api.BuildYear < 1992) return 1.3;
        else if (options.api.BuildYear > 1992 && options.api.BuildYear < 2012) return 2.5;
        else if (options.api.BuildYear > 2012 && options.api.BuildYear < 2015) return 3.5;
        else if (options.api.BuildYear > 2015) return 3.5;
    })();

    const rcFloors = Math.max(rcFloorsPre, isolationFloor);

    const rcCeilings = (() => {
        if (options.api.BuildYear < 1950) return 2;
        else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980) return 2;
        else if (options.api.BuildYear > 1980 && options.api.BuildYear < 1992) return 2;
        else if (options.api.BuildYear > 1992 && options.api.BuildYear < 2012) return 2.5;
        else if (options.api.BuildYear > 2012 && options.api.BuildYear < 2015) return 3.5;
        else if (options.api.BuildYear > 2015) return 4.5;
    })();

    const rcWallsPre = (() => {
        if (options.api.BuildYear < 1950) return 0.5;
        else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980) return 0.8;
        else if (options.api.BuildYear > 1980 && options.api.BuildYear < 1992) return 1.3;
        else if (options.api.BuildYear > 1992 && options.api.BuildYear < 2012) return 2.5;
        else if (options.api.BuildYear > 2012 && options.api.BuildYear < 2015) return 3.5;
        else if (options.api.BuildYear > 2015) return 6;
    })();

    const rcWalls = Math.max(rcWallsPre, isolationWalls);

    const rcGlass = (() => {
        if (options.glass === 'single') return 0.18;
        else if (options.glass === 'double') return 0.33;
        else if (options.glass === 'hr') return 0.7;
        else if (options.glass === 'hrP') return 0.9;
        else if (options.glass === 'triple') return 2;
    })();

    // IMPOSSIBILITIES
    let impossible = false;
    if (options.glass === 'single') {
        impossible = true;
    } else if (options.api.BuildYear < 1950 && options.isolationWalls === 'no') {
        impossible = true;
    } else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980 && options.api.HouseType === 'Vrijstaande woning' && options.isolationWalls === 'no') {
        impossible = true;
    } else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980 && (options.api.HouseType === '2 onder 1 kap woning' || options.api.HouseType === 'Geschakelde 2 onder 1 kapwoning' || options.api.HouseType === 'Meergezinswoning') && options.isolationWalls === 'no') {
        impossible = true;
    } else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980 && (options.api.HouseType === 'Geschakelde woning' || options.api.HouseType === 'Tussen/rijwoning' || options.api.HouseType === 'Eengezinswoning') && options.glass === 'double' && options.isolationFloor === 'no' && options.isolationWalls === 'no') {
        impossible = true;
    } else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980 && (options.api.HouseType === 'Hoekwoning' || 'Eindwoning') && options.glass === 'double' && (options.isolationFloor === 'yes' || options.isolationFloor === 'no') && options.isolationWalls === 'no') {
        impossible = true;
    } else if (options.api.BuildYear > 1950 && options.api.BuildYear < 1980 && (options.api.HouseType === 'Galerijflat' || options.api.HouseType === 'Portiekflat' || options.api.HouseType === 'Corridorflat' || options.api.HouseType === 'Maisonnette' || options.api.HouseType === 'Benedenwoning' || options.api.HouseType === 'Bovenwoning' || options.api.HouseType === 'Portiekwoning') && options.glass === 'double' && options.isolationFloor === 'no' && options.isolationWalls === 'no') {
        impossible = true;
    }

    // INSTALLED POWER
    const wallsInsideSurface = Math.sqrt(options.api.InnerSurfaceArea) * 2.6;
    const wallsOutsideSurface = Math.sqrt(options.api.InnerSurfaceArea) * 2.6;

    const floorInsideCountSurface = (floorInsideCount * Number(options.api.InnerSurfaceArea)) / 2;
    const floorOutsideCountSurface = (floorOutsideCount * Number(options.api.InnerSurfaceArea)) / 2;
    const ceilingInsideCountSurface = (ceilingInsideCount * Number(options.api.InnerSurfaceArea)) / 2;
    const ceilingOutsideCountSurface = (ceilingOutsideCount * Number(options.api.InnerSurfaceArea)) / 2;
    const wallsInsideCountSurface = wallsInsideCount * wallsInsideSurface;
    const wallsOutsideCountSurface = wallsOutsideCount * wallsOutsideSurface;
    const glassCountSurface = (wallsInsideCountSurface + wallsOutsideCountSurface) * glassPercentage;
    const minusWallsOutsideCountSurface = glassCountSurface * -1;

    const floorInsideUValue = 1 / rcFloors;
    const floorOutsideUValue = 1 / rcFloors;
    const ceilingInsideUValue = 1 / rcCeilings;
    const ceilingOutsideUValue = 1 / rcCeilings;
    const wallsInsideUValue = 1 / rcWalls;
    const wallsOutsideUValue = 1 / rcWalls;
    const glassUValue = 1 / rcGlass;
    const minusWallsOutsideUValue = 1 / rcWalls;

    const floorInsideDeltaT = targetTemp - floorInsideAdjacentTemp;
    const floorOutsideDeltaT = targetTemp - floorOutsideAdjacentTemp;
    const ceilingInsideDeltaT = targetTemp - ceilingInsideAdjacentTemp;
    const ceilingOutsideDeltaT = targetTemp - ceilingOutsideAdjacentTemp;
    const wallsInsideDeltaT = targetTemp - wallsInsideAdjacentTemp;
    const wallsOutsideDeltaT = targetTemp - wallsOutsideAdjacentTemp;
    const glassDeltaT = targetTemp - wallsOutsideAdjacentTemp;
    const minusWallsOutsideDeltaT = targetTemp - wallsOutsideAdjacentTemp;

    const floorInsidePower = floorInsideCountSurface * floorInsideUValue * floorInsideDeltaT;
    const floorOutsidePower = floorOutsideCountSurface * floorOutsideUValue * floorOutsideDeltaT;
    const ceilingInsidePower = ceilingInsideCountSurface * ceilingInsideUValue * ceilingInsideDeltaT;
    const ceilingOutsidePower = ceilingOutsideCountSurface * ceilingOutsideUValue * ceilingOutsideDeltaT;
    const wallsInsidePower = wallsInsideCountSurface * wallsInsideUValue * wallsInsideDeltaT;
    const wallsOutsidePower = wallsOutsideCountSurface * wallsOutsideUValue * wallsOutsideDeltaT;
    const glassPower = glassCountSurface * glassUValue * glassDeltaT;
    const minusWallsOutsidePower = minusWallsOutsideCountSurface * minusWallsOutsideUValue * minusWallsOutsideDeltaT;

    const requiredWattage = floorInsidePower + floorOutsidePower + ceilingInsidePower + ceilingOutsidePower + wallsInsidePower + wallsOutsidePower + glassPower + minusWallsOutsidePower;
    const wallsInsideStorage = requiredWattage * extraRequiredWattage;
    const consumptionWattage = requiredWattage + wallsInsideStorage;
    const roundOffStorage = requiredWattage * roundOffPanelsPerRoom;

    let installedPower = consumptionWattage + roundOffStorage;

    // INVESTMENT
    const panelCount = installedPower / panelWattage;
    const panelInvestment = panelCount * panelCosts;
    const domoticaCount = panelCount * domoticaPercentage;
    const domoticaInvestment = domoticaCount * domoticaCosts;
    const investmentVat = (panelInvestment + domoticaInvestment) * vat;

    let investment = (panelInvestment + domoticaInvestment + investmentVat).toFixed(2);

    // SAVINGS PER YEAR
    const energyConsumption = (consumptionWattage * heatingDays * heatingHours) / 1000;
    const energyCosts = energyConsumption * handleParse(options.electricityCosts);
    const totalGasCosts = handleParse(options.gasCosts) * options.gasUsage;
    const heatingConsumption = totalGasCosts * gasForHeating;
    const savingsPerYear = heatingConsumption - energyCosts;
    const paybackPeriod = investment / savingsPerYear;

    const handleBlur = (e) => {
        validate(e.target);
    };
    const validate = (field) => {
        const validity = field.validity;
        if (validity.valid) {
            field.className = 'input--success';
        } else field.className = 'input--error';
        field.reportValidity();
    };

    var formdata = new FormData();
    formdata.append('your-name', formData.firstname);
    formdata.append('your-surname', formData.surname);
    formdata.append('your-email', formData.mail);
    formdata.append('your-savings', currencyFormat.format(savingsPerYear));
    formdata.append('your-usage', Math.round(consumptionWattage) + ' kWh');
    formdata.append('your-investment', currencyFormat.format(investment));
    formdata.append('your-payback', Number(paybackPeriod).toLocaleString(undefined, { maximumFractionDigits: 1 }) + ' jaar');

    return (
        <>
            <div className="container">
                <div className="row justify-content-center m-0">
                    <div className="col-8 success-message">
                        {!impossible && options.success && !options.loading && options.glass && (
                            <div className="message">
                                <div className="stroke"></div>
                                <h2>
                                    Jouw hele woning is geschikt voor het greeniuz home heating system.
                                    <br />
                                    De check is succesvol afgerond!
                                </h2>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="result">
                    {(!options.success || options.loading) && <Inactive />}
                    {options.success && !options.glass && <InactiveGlass />}
                    {impossible && options.success && !options.loading && <Invalid />}
                    <div className="results">
                        <div className="result-items">
                            {savingsPerYear > 0 && (
                                <div className="yearly-savings">
                                    <div className="label">Je jaarlijkse besparing</div>
                                    <div className="circa">circa</div>
                                    <span className="value">{currencyFormat.format(savingsPerYear || 0)}</span>
                                </div>
                            )}
                            <div>
                                <div className="label">Je verbruik</div>
                                <div className="circa">circa</div>
                                <span className="value">{Math.round(consumptionWattage || 0)} kWh</span>
                            </div>
                            <div>
                                <div className="label">Je investering</div>
                                <div className="circa">vanaf</div>
                                <span className="value">{currencyFormat.format(isNaN(investment) ? 0 : investment)}</span>
                            </div>
                            {paybackPeriod > 0 && paybackPeriod < 20 && (
                                <div>
                                    <div className="label">Je terugverdientijd</div>
                                    <div className="circa">circa</div>
                                    <span className="value">{(paybackPeriod || 0).toLocaleString(undefined, { maximumFractionDigits: 1 })} jaar</span>
                                </div>
                            )}
                        </div>
                        <div className="result-buttons">
                            <button className="btn quote-btn" onClick={handleShowForm}>
                                {showForm ? 'Verbergen' : 'Plan een gratis adviesgeprek'}
                            </button>
                        </div>
                        {showForm && (
                            <form className="quote-form" onSubmit={sendForm}>
                                <label>
                                    <input onBlur={handleBlur} type="text" name="firstname" onChange={handleChange} required />
                                    <span>Voornaam</span>
                                </label>
                                <label>
                                    <input onBlur={handleBlur} type="text" name="surname" onChange={handleChange} required />
                                    <span>Achternaam</span>
                                </label>
                                <label>
                                    <input onBlur={handleBlur} type="email" name="mail" onChange={handleChange} required />
                                    <span>E-mailadres</span>
                                </label>
                                <label className="acceptance">
                                    <input onBlur={handleBlur} type="checkbox" name="acceptance" checked={checked} onChange={handleChecked} required />
                                    <span>
                                        Ik ga akkoord met de{' '}
                                        <a href="https://greeniuz.nl/privacy/" title="Privacy voorwaarden" rel="noreferrer" target="_blank">
                                            privacy voorwaarden
                                        </a>
                                    </span>
                                </label>
                                <button className="btn" disabled={isLoading}>
                                    {isLoading ? 'Je wordt doorgestuurd..' : 'Inplannen'}
                                </button>
                                {/* {hasError && <div className="resp-message">{hasError}</div>}
                                {isSent.send && <div className="resp-message">{isSent.message}</div>} */}
                            </form>
                        )}
                    </div>
                </div>
            </div>
            {options.success && !options.loading && <LiveWall options={options} />}
            <div className="container disclaimer">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <p>Om te bepalen of jouw woning geschikt is om te verwarmen met infrarood warmtepanelen, is deze check met de grootste zorgvuldigheid samengesteld. Hierbij zijn zowel de technische specificaties in het berekeningsmodel als een inschatting van de ontwikkeling van de actuele energieprijzen gebruikt. Ook is rekening gehouden met het gemiddelde energieverbruik van een gemiddeld huishouden in het ingevulde postcodegebied. Je kunt deze berekening op ieder moment aanpassen om nog beter aan te laten sluiten op jouw situatie en besparingsmogelijkheden. Aan het gebruik van deze check kunnen geen rechten worden ontleend. Ook biedt deze check geen garantie of toezegging van Greeniuz. Greeniuz kan op geen enkele manier aansprakelijk worden gehouden voor een eventuele afwijking tussen iedere getoonde indicatieve besparing en daadwerkelijk gerealiseerde berekening.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Result;
