import { useEffect, useState } from 'react';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Address from './Address';
import Result from './Result';
import Loading from './Loading';
import { getApiError } from '../utils/apiErrors';
import { toast } from 'react-toastify';
import { ReactComponent as SingleGlass } from '../assets/images/single.svg';
import { ReactComponent as DoubleGlass } from '../assets/images/double.svg';
import { ReactComponent as HrGlass } from '../assets/images/hr.svg';
import { ReactComponent as HrPlusGlass } from '../assets/images/hr++.svg';
import { ReactComponent as TripleGlass } from '../assets/images/triple.svg';
import { ReactComponent as Tooltip } from '../assets/images/tooltip.svg';

const Form = () => {
    const [options, setOptions] = useState({
        loading: false,
        success: false,
        postcode: '',
        housenumber: '',
        houseaddition: '',
        isolationFloor: 'no',
        isolationWalls: 'no',
        glass: '',
        api: {
            PostCode: '',
            HouseNumber: '',
            HouseAddition: '',
            City: '',
            Street: '',
            HouseType: '',
            BuildYear: '',
            InnerSurfaceArea: '',
        },
        gasCosts: (2.96).toLocaleString(undefined, { minimumFractionDigits: 2 }),
        gasUsage: 0,
        electricityCosts: (0.67).toLocaleString(undefined, { minimumFractionDigits: 2 }),
    });

    useEffect(() => {
        localStorage.setItem('greeniuz_quickscan', JSON.stringify(options));
    }, [options]);

    JSON.parse(localStorage.getItem('greeniuz_quickscan'));

    const handleChange = (e) => {
        setOptions({ ...options, [e.target.name]: e.target.value });
    };

    const searchAddress = async (e) => {
        e.preventDefault();
        setOptions({ ...options, loading: true });
        try {
            axios.defaults.withCredentials = false;
            const url = '/.netlify/functions/api';
            const formData = { postcode: options.postcode.toUpperCase().split(' ').join(''), housenumber: options.housenumber, houseaddition: options.houseaddition };
            const res = await axios.post(url, formData);
            setOptions({
                ...options,
                success: true,
                gasUsage: res.data.Output.HouseType === 'Vrijstaande woning' ? 2200 : res.data.Output.HouseType === '2 onder 1 kap woning' ? 1710 : res.data.Output.HouseType === 'Geschakelde 2 onder 1 kapwoning' ? 1710 : res.data.Output.HouseType === 'Meergezinswoning' ? 1710 : res.data.Output.HouseType === 'Geschakelde woning' ? 1290 : res.data.Output.HouseType === 'Tussen/rijwoning' ? 1290 : res.data.Output.HouseType === 'Eengezinswoning' ? 1290 : res.data.Output.HouseType === 'Hoekwoning' ? 1510 : res.data.Output.HouseType === 'Eindwoning' ? 1510 : res.data.Output.HouseType === 'Galerijflat' ? 990 : res.data.Output.HouseType === 'Portiekflat' ? 990 : res.data.Output.HouseType === 'Corridorflat' ? 990 : res.data.Output.HouseType === 'Maisonnette' ? 990 : res.data.Output.HouseType === 'Benedenwoning' ? 990 : res.data.Output.HouseType === 'Bovenwoning' ? 990 : res.data.Output.HouseType === 'Portiekwoning' ? 990 : '',
                api: {
                    PostCode: res.data.Output.PostCode,
                    HouseNumber: res.data.Output.HouseNumber,
                    HouseAddition: res.data.Output.HouseAddition,
                    City: res.data.Output.City,
                    Street: res.data.Output.Street,
                    HouseType: res.data.Output.HouseType,
                    BuildYear: res.data.Output.BuildYear,
                    InnerSurfaceArea: res.data.Output.InnerSurfaceArea,
                    Latitude: res.data.Output.Latitude,
                    Longitude: res.data.Output.Longitude,
                },
            });
            if (!res.data.Output.HouseType) {
                toast.error(getApiError(400));
                setOptions({ ...options, success: false });
            }
        } catch (error) {
            setOptions({ ...options, loading: false });
            setOptions({ ...options, success: false });
            let message;
            if (error.response) {
                const statusCode = error.response.status;
                message = getApiError(statusCode);
            } else {
                message = getApiError(400);
            }
            toast.error(message);
            // toast.error(error.response.data);
        }
    };

    const handleBlur = (e) => {
        validate(e.target);
    };
    const validate = (field) => {
        const validity = field.validity;
        if (validity.valid) {
            field.className = 'input--success';
        } else field.className = 'input--error';
        field.reportValidity();
    };

    return (
        <>
            <div className="container form">
                <div className="row justify-content-center m-0">
                    <div className="col-8 form-bg">
                        {options.loading && <Loading />}
                        <div className="form-group">
                            <form autoComplete="off" className="address-fields" onSubmit={searchAddress}>
                                <div className="row">
                                    <label className="col postcode">
                                        Postcode*
                                        <input onBlur={handleBlur} onChange={handleChange} type="text" name="postcode" value={options.postcode.toUpperCase() || ''} pattern="^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$" required placeholder="1122AB" />
                                    </label>
                                    <label className="col house-number">
                                        Huisnummer*
                                        <input onBlur={handleBlur} onChange={handleChange} type="text" name="housenumber" value={options.housenumber || ''} pattern="\d*" required placeholder="104" />
                                    </label>
                                    <label className="col house-addition">
                                        <div className="tooltip-wrapper">
                                            Toevoeging
                                            <Tooltip data-tip data-for="tooltipHouseAddition" />
                                            <ReactTooltip id="tooltipHouseAddition" className="tooltip" place="top" effect="solid" event="click" scrollHide={true} resizeHide={true} clickable={true}>
                                                Let op het gebruik van een grote of kleine letter.
                                            </ReactTooltip>
                                        </div>
                                        <input onBlur={handleBlur} onChange={handleChange} type="text" name="houseaddition" value={options.houseaddition || ''} placeholder="A" />
                                    </label>
                                </div>
                                <button type="submit" className="btn mt" disabled={options.loading}>
                                    {options.success ? 'Vind een andere woning' : 'Vind mijn woning'}
                                </button>
                            </form>
                            {!options.loading && options.success && <Address options={options} />}
                        </div>
                        <div className={`form-group${options.success ? '' : ' disabled'}`}>
                            <div className="label">
                                <div className="tooltip-wrapper">
                                    Type glas* <Tooltip data-tip data-for="tooltipGlass" />
                                    <ReactTooltip id="tooltipGlass" className="tooltip" place="top" effect="solid" event="click" scrollHide={true} resizeHide={true} clickable={true}>
                                        <>
                                            <strong>Welk type glas hebben mijn ramen?</strong>
                                            <p>Je kan het type glas op twee manieren herkennen, namelijk:</p>
                                            <ol>
                                                <li>Opzoeken in documenten, zoals een bouwkundig rapport, taxatierapport of op de factuur vind je informatie over de fabrikant, het soort glas en de Ug-waarde.</li>
                                                <li>
                                                    Zelf je glas controleren. Hoe je dat doet, lees je{' '}
                                                    <a href="https://greeniuz.nl" target="_blank" rel="noreferrer">
                                                        hier
                                                    </a>
                                                    .
                                                </li>
                                            </ol>
                                        </>
                                    </ReactTooltip>
                                </div>
                            </div>
                            <div className="radios">
                                <span className="radio">
                                    <input onChange={handleChange} type="radio" id="single" name="glass" value="single" checked={options.glass === 'single'} />
                                    <label htmlFor="single">
                                        <SingleGlass />
                                        Enkel
                                    </label>
                                </span>
                                <span className="radio">
                                    <input onChange={handleChange} type="radio" id="double" name="glass" value="double" checked={options.glass === 'double'} />
                                    <label htmlFor="double">
                                        <DoubleGlass />
                                        Dubbel
                                    </label>
                                </span>
                                <span className="radio">
                                    <input onChange={handleChange} type="radio" id="hr" name="glass" value="hr" checked={options.glass === 'hr'} />
                                    <label htmlFor="hr">
                                        <HrGlass />
                                        HR
                                    </label>
                                </span>
                                <span className="radio">
                                    <input onChange={handleChange} type="radio" id="hrP" name="glass" value="hrP" checked={options.glass === 'hrP'} />
                                    <label htmlFor="hrP">
                                        <HrPlusGlass />
                                        HR++
                                    </label>
                                </span>
                                <span className="radio">
                                    <input onChange={handleChange} type="radio" id="triple" name="glass" value="triple" checked={options.glass === 'triple'} />
                                    <label htmlFor="triple">
                                        <TripleGlass />
                                        Triple
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className={`form-group${options.success ? '' : ' disabled'}`}>
                            <div className="row">
                                <label className="col-6 gas-price m-mobile">
                                    <div className="tooltip-wrapper">
                                        Gemiddelde gasprijs*
                                        <Tooltip data-tip data-for="tooltipGasPrice" />
                                        <ReactTooltip id="tooltipGasPrice" className="tooltip" place="top" effect="solid" event="click" scrollHide={true} resizeHide={true} clickable={true}>
                                            Momenteel betreft de gasprijs gemiddeld 2,966 euro per m3. Dit tarief is inclusief energiebelasting, ODE en 9% BTW. Vul hier jouw gasprijs in voor een nauwkeurig resultaat.
                                        </ReactTooltip>
                                    </div>
                                    <span className="input">
                                        <input onChange={handleChange} type="text" name="gasCosts" value={options.gasCosts} required placeholder="gasprijs" />
                                        <span className="suffix">€/m³</span>
                                    </span>
                                </label>
                                <label className="col-6 gas-usage">
                                    <div className="tooltip-wrapper">
                                        Totaal gasverbruik*
                                        <Tooltip data-tip data-for="tooltipGasUsage" />
                                        <ReactTooltip id="tooltipGasUsage" className="tooltip" place="top" effect="solid" event="click" scrollHide={true} resizeHide={true} clickable={true}>
                                            Wij baseren het gasverbruik voor de verwarming op 80% van het totale gasverbruik. Vul hier jouw gasprijs in voor een nauwkeurig resultaat.
                                        </ReactTooltip>
                                    </div>
                                    <span className="input">
                                        <input onChange={handleChange} type="number" name="gasUsage" value={options.gasUsage} required placeholder="totaal gasverbruik" />
                                        <span className="suffix">m³/jaar</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className={`form-group${options.success ? '' : ' disabled'}`}>
                            <div className="row">
                                <label className="col-6 electricity-price">
                                    <div className="tooltip-wrapper">
                                        Je huidige elektriciteitsprijs*
                                        <Tooltip data-tip data-for="tooltipGasUsage" />
                                        <ReactTooltip id="tooltipGasUsage" className="tooltip" place="top" effect="solid" event="click" scrollHide={true} resizeHide={true} clickable={true}>
                                            Momenteel betreft de stroomprijs gemiddeld 0,670 euro per kWh. Dit tarief is inclusief energiebelasting, ODE en 9% BTW. Vul hier jouw elektriciteitsprijs in voor een nauwkeurig resultaat.
                                        </ReactTooltip>
                                    </div>
                                    <span className="input">
                                        <input onChange={handleChange} type="text" name="electricityCosts" value={options.electricityCosts} required placeholder="stroomprijs" />
                                        <span className="suffix">€/kWh</span>
                                    </span>
                                </label>
                            </div>
                        </div>
                        {options.api.BuildYear && options.api.BuildYear < 1992 && (
                            <>
                                <div className={`form-group${options.success ? '' : ' disabled'}`}>
                                    <p className="label">Is jouw vloer aanvullend geïsoleerd?</p>
                                    <span className="single-radio">
                                        <input onChange={handleChange} type="radio" id="isolationFloorNo" name="isolationFloor" value="no" checked={options.isolationFloor === 'no'} />
                                        <label htmlFor="isolationFloorNo">Nee</label>
                                    </span>
                                    <span className="single-radio">
                                        <input onChange={handleChange} type="radio" id="isolationFloorYes" name="isolationFloor" value="yes" checked={options.isolationFloor === 'yes'} />
                                        <label htmlFor="isolationFloorYes">Ja</label>
                                    </span>
                                </div>
                                <div className={`form-group${options.success ? '' : ' disabled'}`}>
                                    <p className="label">Is jouw gevel aanvullend geïsoleerd?</p>
                                    <span className="single-radio">
                                        <input onChange={handleChange} type="radio" id="isolationWallsNo" name="isolationWalls" value="no" checked={options.isolationWalls === 'no'} />
                                        <label htmlFor="isolationWallsNo">Nee</label>
                                    </span>
                                    <span className="single-radio">
                                        <input onChange={handleChange} type="radio" id="isolationWallsYes" name="isolationWalls" value="yes" checked={options.isolationWalls === 'yes'} />
                                        <label htmlFor="isolationWallsYes">Ja</label>
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <Result options={options} />
        </>
    );
};

export default Form;
