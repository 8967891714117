const Header = () => {
    return (
        <>
            <div className="staging">Staging</div>
            <header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4">
                            <a className="social-icon-link github" href="https://greeniuz.nl" rel="noopener" title="Terug naar greeniuz" aria-label="Terug naar greeniuz">
                                Terug naar greeniuz
                            </a>
                        </div>
                        <div className="col-4 text-center">
                            <svg width="259" height="86" viewBox="0 0 259 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_6_1158)">
                                    <path d="M48.2026 80.3735L48.2472 67.1085H49.9183L49.896 73.0763C50.1515 72.5857 50.5725 72.2015 51.0844 71.9919C51.5998 71.7516 52.1609 71.625 52.7295 71.6205C53.2028 71.5976 53.6756 71.6765 54.1157 71.8521C54.5559 72.0276 54.9532 72.2957 55.2808 72.6381C55.9009 73.3102 56.2017 74.2461 56.1943 75.4419L56.1757 80.4107H54.5046L54.5232 75.5533C54.5558 74.9458 54.3567 74.3486 53.9662 73.8821C53.7826 73.6799 53.5574 73.5198 53.3061 73.4127C53.0549 73.3057 52.7834 73.2543 52.5104 73.262C51.8849 73.258 51.2741 73.4517 50.765 73.8153C50.4863 74.0151 50.2658 74.2856 50.1263 74.5989C49.9619 74.964 49.8795 75.3608 49.8849 75.7612V80.3958L48.2026 80.3735Z" fill="white" />
                                    <path
                                        d="M58.9163 78.4462C58.501 77.728 58.2892 76.9102 58.3035 76.0806C58.2939 75.2579 58.511 74.4485 58.9311 73.7411C59.3241 73.0785 59.8937 72.5384 60.5763 72.1813C61.2717 71.8275 62.0409 71.6431 62.8211 71.6431C63.6014 71.6431 64.3706 71.8275 65.066 72.1813C65.7456 72.5422 66.3104 73.0864 66.6963 73.7522C67.1123 74.4632 67.3231 75.2756 67.3053 76.0992C67.3152 76.9278 67.0981 77.7433 66.6777 78.4573C66.2895 79.1299 65.721 79.6804 65.0363 80.0468C64.3437 80.4077 63.5743 80.5961 62.7933 80.5961C62.0123 80.5961 61.2429 80.4077 60.5503 80.0468C59.8672 79.6758 59.3013 79.1215 58.9163 78.4462ZM59.9747 76.0881C59.9734 76.4597 60.0454 76.8279 60.1865 77.1717C60.3276 77.5155 60.535 77.8281 60.7969 78.0917C61.0588 78.3554 61.3701 78.5648 61.713 78.7082C62.0558 78.8515 62.4235 78.9259 62.7951 78.9271C63.1668 78.9283 63.535 78.8563 63.8788 78.7153C64.2226 78.5742 64.5352 78.3668 64.7988 78.1049C65.0624 77.8429 65.2719 77.5317 65.4152 77.1888C65.5586 76.846 65.633 76.4782 65.6342 76.1066C65.6513 75.7288 65.588 75.3517 65.4485 75.0003C65.3089 74.6488 65.0963 74.331 64.8246 74.0679C64.274 73.5684 63.5571 73.2917 62.8137 73.2917C62.0703 73.2917 61.3535 73.5684 60.8028 74.0679C60.5302 74.3272 60.3155 74.6413 60.1728 74.9894C60.0301 75.3376 59.9626 75.712 59.9747 76.0881Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M69.5076 80.4515L69.541 71.862H71.2084V73.1469C71.4304 72.704 71.7918 72.3465 72.2371 72.1293C72.6524 71.8985 73.1117 71.758 73.5851 71.7171C73.9006 71.6974 74.2174 71.7161 74.5284 71.7728C75.0181 71.8258 75.4842 72.011 75.8767 72.3087C76.2693 72.6064 76.5734 73.0052 76.7565 73.4625C77.0222 72.9083 77.4462 72.4452 77.975 72.1319C78.5038 71.8186 79.1137 71.6691 79.7274 71.7023C80.1859 71.6742 80.6449 71.7502 81.07 71.9245C81.495 72.0987 81.8752 72.3668 82.1821 72.7087C82.7503 73.3734 83.0288 74.3129 83.0251 75.5236V80.4887H81.354V75.6276C81.3916 75.0233 81.2067 74.4262 80.8341 73.949C80.6543 73.7447 80.4307 73.5837 80.1798 73.4782C79.929 73.3728 79.6575 73.3255 79.3858 73.34C79.0744 73.3233 78.7633 73.3767 78.4754 73.4962C78.1875 73.6158 77.93 73.7984 77.7221 74.0307C77.295 74.5342 77.0724 75.1795 77.0982 75.8392L77.0796 80.4738H75.4085L75.4456 75.6016C75.482 75.0067 75.3057 74.4183 74.948 73.9416C74.7843 73.7421 74.5769 73.5831 74.3418 73.4769C74.1068 73.3706 73.8504 73.3199 73.5925 73.3288C72.9696 73.3121 72.3639 73.5339 71.8991 73.949C71.6533 74.1929 71.4632 74.4871 71.342 74.8115C71.2209 75.1359 71.1715 75.4828 71.1973 75.8281L71.1787 80.4627L69.5076 80.4515Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M85.153 76.1846C85.127 75.5956 85.2232 75.0076 85.4356 74.4577C85.648 73.9077 85.9719 73.4076 86.387 72.989C86.8022 72.5704 87.2995 72.2423 87.8476 72.0253C88.3958 71.8083 88.983 71.7072 89.5722 71.7283C90.1391 71.7116 90.7034 71.8125 91.2294 72.0247C91.7554 72.2369 92.2318 72.5558 92.6285 72.9612C93.0356 73.3924 93.3512 73.9017 93.5561 74.4583C93.7609 75.0148 93.8509 75.6071 93.8205 76.1994C93.8205 76.5151 93.8205 76.7342 93.802 76.8567L86.8241 76.8308C86.8971 77.4947 87.2229 78.1051 87.7339 78.5353C88.2553 78.9657 88.9149 79.1926 89.5907 79.174C90.0943 79.1811 90.5917 79.0637 91.039 78.8324C91.4668 78.6156 91.8209 78.2771 92.0566 77.8594L92.8253 78.1639L93.49 78.4202C93.1438 79.1358 92.5813 79.7243 91.882 80.1024C91.1617 80.4959 90.352 80.6967 89.5313 80.6855C88.952 80.6988 88.376 80.594 87.8384 80.3776C87.3009 80.1611 86.813 79.8376 86.4044 79.4266C85.9856 79.0002 85.6585 78.4926 85.4433 77.9351C85.228 77.3775 85.1292 76.7818 85.153 76.1846ZM86.8278 75.5421H92.1531C92.0865 74.8947 91.7837 74.2945 91.3027 73.8562C90.8193 73.4377 90.1963 73.2164 89.5573 73.236C88.895 73.2139 88.2468 73.43 87.7302 73.845C87.22 74.2717 86.8962 74.8805 86.8278 75.5421Z"
                                        fill="white"
                                    />
                                    <path d="M100.717 80.5593L100.761 67.2905H102.432L102.41 73.2546C102.667 72.7628 103.089 72.3774 103.602 72.1665C104.118 71.9261 104.679 71.7994 105.247 71.7951C105.72 71.7714 106.193 71.8497 106.633 72.0246C107.073 72.1995 107.471 72.467 107.799 72.809C108.415 73.4848 108.719 74.4169 108.708 75.6164L108.69 80.5853H107.022V75.7241C107.057 75.1181 106.859 74.5216 106.469 74.0567C106.285 73.8542 106.059 73.6939 105.807 73.5869C105.555 73.4798 105.283 73.4286 105.01 73.4366C104.702 73.4352 104.397 73.4816 104.103 73.574C103.804 73.668 103.521 73.807 103.264 73.9862C102.988 74.188 102.769 74.458 102.629 74.7697C102.463 75.1345 102.381 75.5315 102.388 75.9321L102.369 80.5704L100.717 80.5593Z" fill="white" />
                                    <path
                                        d="M110.818 76.2737C110.793 75.6863 110.891 75.1002 111.103 74.5522C111.316 74.0042 111.64 73.5062 112.055 73.0894C112.47 72.6726 112.966 72.3461 113.513 72.1304C114.06 71.9147 114.646 71.8145 115.233 71.836C115.8 71.8188 116.365 71.9191 116.892 72.1306C117.418 72.3422 117.895 72.6604 118.293 73.0652C118.699 73.4974 119.014 74.0068 119.218 74.5632C119.423 75.1195 119.514 75.7113 119.485 76.3034C119.485 76.6228 119.485 76.8419 119.467 76.9608L112.485 76.9348C112.56 77.598 112.886 78.2077 113.395 78.6393C113.917 79.0695 114.576 79.2976 115.252 79.2818C115.755 79.2866 116.253 79.168 116.7 78.9364C117.129 78.7219 117.484 78.3828 117.718 77.9634L118.49 78.2679L119.155 78.5242C118.808 79.239 118.245 79.8273 117.547 80.2064C116.827 80.6011 116.017 80.802 115.196 80.7895C114.617 80.8028 114.041 80.698 113.503 80.4816C112.966 80.2651 112.478 79.9416 112.069 79.5306C111.648 79.1026 111.32 78.5926 111.105 78.0323C110.889 77.472 110.792 76.8734 110.818 76.2737ZM112.493 75.635H117.818C117.752 74.9855 117.448 74.3836 116.964 73.9453C116.481 73.5284 115.86 73.3072 115.222 73.3251C114.56 73.3024 113.911 73.5186 113.395 73.9342C112.885 74.3625 112.561 74.9724 112.493 75.635Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M121.041 76.3443C121.026 75.5163 121.237 74.6999 121.65 73.9824C122.024 73.3212 122.576 72.7783 123.243 72.4153C123.911 72.0565 124.658 71.8737 125.416 71.8842C126.029 71.884 126.634 72.0171 127.191 72.2741C127.709 72.4976 128.141 72.8817 128.424 73.3697V72.0848H130.11L130.08 80.6743H128.409V79.356C128.12 79.8488 127.683 80.2385 127.161 80.4701C126.605 80.7231 126 80.8499 125.39 80.8414C124.818 80.8503 124.251 80.7433 123.722 80.527C123.193 80.3106 122.713 79.9893 122.311 79.5825C121.887 79.1603 121.554 78.6544 121.336 78.0969C121.117 77.5394 121.017 76.9426 121.041 76.3443ZM122.746 76.3443C122.732 76.7244 122.795 77.1033 122.933 77.4578C123.071 77.8123 123.28 78.1348 123.548 78.4053C123.806 78.6699 124.115 78.8788 124.457 79.0193C124.798 79.1598 125.165 79.2289 125.535 79.2223C126.284 79.242 127.012 78.9674 127.562 78.4573C127.842 78.1881 128.061 77.8625 128.205 77.502C128.349 77.1415 128.415 76.7544 128.398 76.3665C128.41 75.8208 128.262 75.2836 127.971 74.8217C127.711 74.4074 127.344 74.0712 126.909 73.8487C126.487 73.6413 126.023 73.5345 125.553 73.5368C125.181 73.5233 124.81 73.5866 124.463 73.7229C124.116 73.8592 123.801 74.0656 123.538 74.329C123.275 74.5924 123.068 74.9074 122.932 75.2541C122.796 75.6008 122.732 75.9719 122.746 76.3443Z"
                                        fill="white"
                                    />
                                    <path d="M131.967 73.4922V72.0811H133.519V68.8354H135.19V72.0774H137.013V73.4885H135.19L135.164 80.6669H133.493L133.519 73.4885L131.967 73.4922Z" fill="white" />
                                    <path d="M138.926 69.7118C138.82 69.6124 138.735 69.4922 138.677 69.3587C138.619 69.2252 138.589 69.0812 138.589 68.9356C138.589 68.7901 138.619 68.6461 138.677 68.5125C138.735 68.379 138.82 68.2589 138.926 68.1595C139.146 67.9556 139.436 67.8451 139.736 67.8513C140.042 67.8436 140.339 67.9555 140.564 68.1632C140.671 68.2626 140.756 68.383 140.814 68.5169C140.873 68.6507 140.903 68.7952 140.903 68.9412C140.903 69.0872 140.873 69.2317 140.814 69.3655C140.756 69.4994 140.671 69.6198 140.564 69.7192C140.336 69.9248 140.038 70.034 139.732 70.0237C139.433 70.0275 139.144 69.9158 138.926 69.7118ZM138.863 80.6929L138.893 72.1071H140.564L140.534 80.6966L138.863 80.6929Z" fill="white" />
                                    <path d="M143.33 80.7078L143.36 72.1219H145.031V73.4031C145.278 72.9226 145.678 72.5383 146.168 72.3113C146.651 72.0709 147.183 71.9439 147.724 71.9399C148.181 71.9131 148.638 71.9896 149.062 72.1638C149.486 72.338 149.865 72.6055 150.171 72.9463C150.739 73.6148 151.021 74.5506 151.014 75.7612V80.73H149.343V75.8689C149.381 75.2687 149.206 74.674 148.849 74.1904C148.679 73.9889 148.465 73.8292 148.223 73.7236C147.982 73.618 147.719 73.5694 147.456 73.5814C147.152 73.5787 146.85 73.6251 146.561 73.7188C146.281 73.8123 146.021 73.9557 145.792 74.1421C145.544 74.3526 145.353 74.622 145.235 74.9257C145.088 75.2925 145.016 75.6853 145.024 76.0806L145.005 80.7152L143.33 80.7078Z" fill="white" />
                                    <path
                                        d="M153.134 76.478C153.12 75.6477 153.33 74.829 153.743 74.1087C154.118 73.4431 154.669 72.8942 155.336 72.523C156.002 72.1586 156.75 71.9731 157.509 71.9845C158.122 71.9824 158.727 72.1222 159.277 72.393C159.794 72.6292 160.227 73.0179 160.517 73.5071V72.1665H162.188L162.162 79.4489C162.147 83.1625 160.57 85.0193 157.431 85.0193C156.633 85.0209 155.85 84.8031 155.168 84.3899C154.485 83.9766 153.93 83.3837 153.561 82.676C153.643 82.6426 153.884 82.5163 154.304 82.3046L154.976 81.9333C155.216 82.3857 155.581 82.7596 156.027 83.0102C156.461 83.2622 156.952 83.3978 157.453 83.4038C159.493 83.4038 160.517 82.114 160.524 79.5343C160.527 79.4997 160.527 79.4649 160.524 79.4303C160.524 79.3857 160.524 79.3523 160.524 79.3263C160.254 79.8372 159.822 80.2436 159.295 80.4812C158.739 80.758 158.126 80.9004 157.505 80.8972C156.748 80.9036 156.002 80.717 155.336 80.355C154.673 79.9897 154.124 79.4471 153.751 78.7878C153.344 78.0863 153.131 77.289 153.134 76.478ZM154.839 76.478C154.82 76.8579 154.881 77.2375 155.018 77.5925C155.155 77.9474 155.365 78.2698 155.634 78.539C155.897 78.795 156.21 78.9958 156.552 79.1297C156.895 79.2636 157.26 79.3279 157.628 79.3189C157.981 79.32 158.332 79.257 158.664 79.1332C158.997 79.0102 159.307 78.8292 159.577 78.5984C159.861 78.3525 160.087 78.0468 160.238 77.7035C160.407 77.3249 160.493 76.9147 160.491 76.5003C160.512 76.1125 160.45 75.7246 160.31 75.3627C160.169 75.0008 159.952 74.6733 159.674 74.4021C159.124 73.8916 158.396 73.6158 157.646 73.6333C157.277 73.6239 156.91 73.6902 156.568 73.8281C156.226 73.9661 155.915 74.1728 155.656 74.4355C155.388 74.6996 155.176 75.0157 155.035 75.3643C154.893 75.7129 154.824 76.0868 154.831 76.4631L154.839 76.478Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M169.08 78.2197H170.625C170.659 78.4232 170.736 78.617 170.852 78.7879C170.97 78.97 171.132 79.1194 171.323 79.2223C171.73 79.4662 172.203 79.5742 172.675 79.5306C173.018 79.5173 173.348 79.3935 173.615 79.1778C173.73 79.0833 173.824 78.9644 173.888 78.8295C173.952 78.6947 173.986 78.5473 173.986 78.3979C173.994 78.2631 173.971 78.1282 173.918 78.0039C173.865 77.8796 173.784 77.7691 173.682 77.6812C173.436 77.4861 173.147 77.3524 172.839 77.2913L171.665 77.009C170.975 76.8599 170.344 76.5127 169.849 76.0101C169.647 75.7604 169.501 75.4707 169.42 75.1602C169.338 74.8497 169.324 74.5255 169.377 74.209C169.412 73.8929 169.516 73.5886 169.684 73.3185C169.852 73.0485 170.078 72.8198 170.347 72.6493C170.94 72.2298 171.652 72.0124 172.378 72.0291C172.728 72.0258 173.077 72.0721 173.414 72.1665C173.732 72.2581 174.032 72.4024 174.302 72.5936C174.578 72.7864 174.805 73.0406 174.966 73.3363C175.147 73.682 175.253 74.0614 175.278 74.4504H173.73C173.715 74.3056 173.669 74.1658 173.594 74.0412C173.519 73.9166 173.417 73.8102 173.295 73.7299C173.029 73.5579 172.717 73.4688 172.4 73.4737C172.106 73.4671 171.815 73.543 171.561 73.6928C171.452 73.751 171.355 73.8306 171.277 73.927C171.2 74.0233 171.142 74.1344 171.108 74.2535C171.03 74.4785 171.03 74.723 171.108 74.948C171.218 75.122 171.363 75.2713 171.534 75.3864C171.704 75.5015 171.897 75.5798 172.1 75.6164L173.366 75.9284C174.851 76.2997 175.609 77.1365 175.639 78.4388C175.645 78.8067 175.558 79.1703 175.385 79.4952C175.213 79.8201 174.96 80.0955 174.651 80.2956C173.949 80.7745 173.112 81.0166 172.263 80.9863C171.745 80.9871 171.233 80.8796 170.759 80.6707C170.283 80.4625 169.871 80.1303 169.567 79.7088C169.255 79.2743 169.086 78.7543 169.08 78.2197Z"
                                        fill="white"
                                    />
                                    <path d="M176.593 72.2371H178.383L180.931 78.4611L183.53 72.2556H185.316L179.935 85.0007H178.182L180.006 80.5704L176.593 72.2371Z" fill="white" />
                                    <path
                                        d="M186.248 78.2828H187.793C187.826 78.4878 187.903 78.6831 188.02 78.8547C188.139 79.034 188.299 79.1818 188.488 79.2855C188.894 79.5294 189.368 79.6373 189.839 79.5937C190.184 79.5804 190.514 79.4567 190.783 79.2409C190.898 79.1461 190.991 79.0271 191.055 78.8924C191.119 78.7576 191.153 78.6103 191.154 78.4611C191.163 78.3266 191.14 78.1919 191.087 78.068C191.034 77.9441 190.953 77.8345 190.85 77.7481C190.604 77.5575 190.316 77.4299 190.01 77.3767L188.833 77.0945C188.145 76.9439 187.516 76.5968 187.021 76.0955C186.818 75.8457 186.671 75.5555 186.589 75.2443C186.507 74.9331 186.492 74.608 186.545 74.2907C186.582 73.9753 186.688 73.672 186.856 73.4024C187.023 73.1327 187.248 72.9035 187.515 72.731C188.108 72.3123 188.82 72.0949 189.546 72.1108C189.897 72.1094 190.247 72.1556 190.586 72.2482C190.904 72.3375 191.205 72.4793 191.477 72.6678C191.757 72.8657 191.986 73.1265 192.146 73.4291C192.324 73.7751 192.429 74.1545 192.454 74.5432H190.909C190.894 74.3981 190.847 74.2581 190.771 74.1335C190.695 74.0088 190.593 73.9027 190.471 73.8228C190.211 73.6641 189.915 73.5771 189.611 73.5706C189.306 73.564 189.006 73.6382 188.74 73.7856C188.63 73.8438 188.533 73.9234 188.455 74.0197C188.377 74.116 188.318 74.2271 188.283 74.3464C188.206 74.5714 188.206 74.8158 188.283 75.0408C188.392 75.2161 188.536 75.3663 188.707 75.4816C188.878 75.5968 189.072 75.6744 189.275 75.7093L190.538 76.0249C192.023 76.3938 192.781 77.2306 192.81 78.5353C192.818 78.903 192.731 79.2665 192.559 79.5914C192.387 79.9163 192.135 80.1919 191.826 80.3921C191.124 80.8699 190.287 81.1118 189.438 81.0829C188.919 81.0859 188.405 80.9783 187.931 80.7672C187.456 80.5574 187.046 80.227 186.739 79.8091C186.417 79.3655 186.245 78.8308 186.248 78.2828Z"
                                        fill="white"
                                    />
                                    <path d="M194.051 73.7113V72.3001H195.603V69.0582H197.274V72.3001H199.097V73.7447H197.274L197.248 80.9194H195.577L195.603 73.741L194.051 73.7113Z" fill="white" />
                                    <path
                                        d="M200.297 76.5894C200.271 76.0007 200.367 75.413 200.579 74.8633C200.791 74.3136 201.115 73.8138 201.53 73.3952C201.945 72.9767 202.441 72.6484 202.989 72.4312C203.537 72.214 204.124 72.1125 204.712 72.133C205.279 72.1193 205.841 72.2223 206.366 72.4357C206.891 72.6491 207.366 72.9683 207.761 73.3734C208.167 73.8056 208.482 74.315 208.686 74.8713C208.891 75.4277 208.982 76.0195 208.953 76.6116C208.953 76.9273 208.953 77.1464 208.931 77.2652L201.953 77.2429C202.026 77.9069 202.352 78.5173 202.863 78.9475C203.385 79.3773 204.044 79.6041 204.72 79.5862C205.223 79.5932 205.721 79.4759 206.168 79.2446C206.596 79.0278 206.95 78.6893 207.186 78.2716L207.958 78.5761L208.623 78.8324C208.275 79.5482 207.711 80.1367 207.011 80.5146C206.29 80.9068 205.481 81.1075 204.66 81.0977C204.081 81.1105 203.505 81.0055 202.968 80.7891C202.43 80.5727 201.942 80.2494 201.534 79.8388C201.117 79.4103 200.792 78.9009 200.579 78.3421C200.366 77.7832 200.27 77.1867 200.297 76.5894ZM201.968 75.9469H207.293C207.228 75.299 206.925 74.6982 206.443 74.2609C205.96 73.8425 205.337 73.6211 204.698 73.6408C204.037 73.6192 203.391 73.8338 202.874 74.2461C202.357 74.6708 202.028 75.2816 201.957 75.9469H201.968Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M211.066 80.9454L211.096 72.3596H212.767V73.6408C212.99 73.1997 213.352 72.8436 213.796 72.627C214.214 72.3969 214.676 72.2589 215.151 72.2222C215.467 72.2025 215.784 72.2212 216.095 72.2779C216.584 72.3313 217.05 72.5167 217.443 72.8143C217.835 73.1119 218.139 73.5105 218.323 73.9676C218.588 73.4127 219.012 72.9491 219.541 72.6357C220.07 72.3222 220.68 72.1731 221.294 72.2073C221.753 72.1793 222.212 72.2552 222.638 72.4295C223.064 72.6037 223.444 72.8718 223.752 73.2137C224.317 73.8797 224.598 74.818 224.595 76.0286L224.576 80.9937H222.905L222.924 76.1326C222.962 75.5284 222.777 74.9313 222.404 74.4541C222.224 74.2504 222 74.0899 221.749 73.9844C221.499 73.879 221.227 73.8314 220.956 73.845C220.644 73.8275 220.333 73.8806 220.045 74.0002C219.757 74.1198 219.499 74.3029 219.292 74.5358C218.865 75.0377 218.641 75.6817 218.664 76.3406V80.9789H216.993V76.1029C217.028 75.5083 216.852 74.9204 216.496 74.4429C216.334 74.2432 216.129 74.0831 215.896 73.975C215.663 73.8668 215.408 73.8135 215.151 73.819C214.528 73.8023 213.923 74.0241 213.458 74.4392C213.211 74.6826 213.02 74.9767 212.898 75.3012C212.777 75.6256 212.727 75.9727 212.752 76.3183V80.9529L211.066 80.9454Z"
                                        fill="white"
                                    />
                                    <path d="M257.858 10.6432L239.542 30.4219L258.712 30.4887L258.69 35.9477L234.863 35.866C234.409 35.8645 233.96 35.7737 233.541 35.5986C233.122 35.4235 232.742 35.1676 232.422 34.8455C232.102 34.5234 231.848 34.1414 231.676 33.7214C231.503 33.3014 231.415 32.8515 231.417 32.3975C231.419 31.5397 231.741 30.7136 232.319 30.0802L250.33 10.2978L232.505 10.2347V4.77942L255.347 4.8574C256.261 4.86232 257.136 5.22937 257.78 5.87813C258.424 6.52689 258.785 7.40444 258.783 8.31848C258.778 9.18222 258.448 10.0125 257.858 10.6432Z" fill="white" />
                                    <path d="M169.901 17.6693L169.845 35.6357L164.052 35.6134L164.115 17.9775C164.134 12.7785 160.951 9.58107 156.625 9.56622C152.299 9.55136 147.1 11.9949 147.074 18.7165L147.014 35.554H141.225L141.336 4.47115L147.126 4.49343L147.107 9.21714C148.986 5.42926 154.048 3.85098 157.442 3.86212C165.155 3.87326 169.998 8.94604 169.901 17.6693Z" fill="white" />
                                    <path d="M32.4644 4.07008L32.3715 30.4367C32.3195 45.15 25.6462 50.635 15.3966 50.6016C9.74081 50.5793 4.49349 47.7012 1.44833 42.2311L6.38371 39.6539C7.26565 41.2893 8.56711 42.6604 10.1544 43.6263C11.7416 44.5922 13.5574 45.1181 15.4152 45.15C22.2705 45.176 26.556 41.3287 26.582 30.8118V30.2064C24.5061 33.7938 19.9755 35.7768 15.5155 35.7583C6.99645 35.7286 -0.0371117 29.2483 2.43127e-05 19.463C0.0334468 9.6145 7.05587 3.31622 15.5637 3.34593C20.2206 3.3645 24.6064 5.30671 26.6563 8.91634V4.05894L32.4644 4.07008ZM26.6191 19.4927C26.6451 12.7711 21.201 8.8235 16.0762 8.80493C10.4204 8.78636 5.87866 13.161 5.85638 19.4184C5.8341 25.609 10.3424 30.2956 16.0019 30.2956C21.5909 30.3216 26.5969 26.1475 26.6191 19.4927Z" fill="white" />
                                    <path d="M133.363 18.2641C133.361 18.2491 133.361 18.2339 133.363 18.2189C133.361 18.2339 133.361 18.2491 133.363 18.2641Z" fill="white" />
                                    <path
                                        d="M118.141 30.667C112.812 30.667 108.831 27.904 107.984 22.3782L129.523 22.4525C131.647 22.4525 133.374 21.0042 133.382 18.62C133.38 18.3707 133.355 18.122 133.307 17.8773C133.334 17.9997 133.353 18.1238 133.363 18.2487C132.698 9.336 126.385 3.76559 118.037 3.73588C109.187 3.70618 102.042 9.93389 102.005 19.7861C101.968 29.6383 109.061 36.1259 117.922 36.1482C123.916 36.1705 129.516 33.3964 132.264 28.0117L127.213 25.9989C125.468 29.0924 121.94 30.667 118.141 30.667ZM132.442 16.0948C132.397 16.0502 132.357 16.0019 132.312 15.9536C132.357 15.9982 132.397 16.0465 132.442 16.091V16.0948ZM133.27 17.7102C133.252 17.6396 133.237 17.5691 133.214 17.4985C133.237 17.5654 133.252 17.6359 133.27 17.7065V17.7102ZM133.162 17.3388C133.14 17.2757 133.118 17.2126 133.092 17.1531C133.118 17.2126 133.14 17.2757 133.162 17.3388ZM133.025 16.9972C132.995 16.9415 132.969 16.882 132.94 16.8226C132.969 16.882 132.995 16.9266 133.025 16.9972ZM132.858 16.6778L132.758 16.5144L132.858 16.6778ZM132.661 16.3733L132.55 16.221L132.661 16.3733ZM129.928 14.7801C129.805 14.7656 129.681 14.7582 129.557 14.7579C129.681 14.7582 129.805 14.7656 129.928 14.7801ZM132.193 15.8348L132.052 15.7048L132.193 15.8348ZM131.926 15.5971C131.876 15.5558 131.824 15.5174 131.77 15.482C131.824 15.5174 131.876 15.5558 131.926 15.5971ZM131.636 15.3892L131.469 15.2852L131.636 15.3892ZM131.328 15.2035L131.146 15.1144L131.328 15.2035ZM130.997 15.0512L130.804 14.977L130.997 15.0512ZM130.656 14.925L130.44 14.8693L130.656 14.925ZM130.284 14.8358L130.039 14.7987L130.284 14.8358ZM118.015 9.16518C122.49 9.16518 125.939 11.423 127.221 15.534C127.412 16.1603 127.551 16.8012 127.637 17.4502H125.865L108.07 17.3871C109.02 11.7944 112.756 9.14661 118.015 9.16518Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M81.0234 30.5333C75.6981 30.5333 71.7171 27.7703 70.8704 22.2408L92.4093 22.3188C94.5335 22.3188 96.2603 20.8705 96.2678 18.4863C96.2708 18.2308 96.2471 17.9757 96.1972 17.725C96.2195 17.8439 96.238 17.9627 96.2492 18.0964C95.5845 9.18375 89.2713 3.61335 80.9231 3.58364C72.0662 3.55764 64.9212 9.77422 64.8878 19.6264C64.8544 29.4786 71.9437 35.9663 80.8043 35.9886C86.7944 36.0108 92.3945 33.2331 95.1425 27.852L90.092 25.8393C88.3504 28.9587 84.8039 30.5444 81.0234 30.5333ZM95.3245 15.9611L95.202 15.8125L95.3245 15.9611ZM96.1526 17.5765C96.1341 17.5059 96.1192 17.4354 96.0969 17.3648C96.1192 17.4354 96.1341 17.5059 96.1526 17.5765ZM96.0449 17.2051L95.9781 17.0195L96.0449 17.2051ZM95.9075 16.8635C95.8815 16.8041 95.8518 16.7484 95.8221 16.6889C95.8518 16.7521 95.8815 16.8078 95.9075 16.8672V16.8635ZM95.7404 16.5441C95.707 16.4884 95.6773 16.4327 95.6402 16.3807C95.6773 16.4364 95.707 16.4921 95.7404 16.5553V16.5441ZM95.5473 16.2396L95.4322 16.0873L95.5473 16.2396ZM92.8104 14.6465C92.6871 14.6319 92.5631 14.6245 92.439 14.6242C92.5632 14.6257 92.6872 14.6344 92.8104 14.6502V14.6465ZM95.0794 15.7011L94.9383 15.5712L95.0794 15.7011ZM94.8083 15.4635C94.76 15.4226 94.708 15.3855 94.656 15.3483C94.708 15.3892 94.76 15.4412 94.8083 15.4672V15.4635ZM94.5186 15.2518L94.3515 15.1515L94.5186 15.2518ZM94.2104 15.0698L94.0285 14.9807L94.2104 15.0698ZM93.8836 14.9176C93.8195 14.889 93.7538 14.8642 93.6868 14.8433C93.7539 14.8654 93.8196 14.8914 93.8836 14.9213V14.9176ZM93.5383 14.7913L93.3266 14.7356L93.5383 14.7913ZM93.1669 14.7022C93.0852 14.6836 93.0035 14.6762 92.9218 14.665C93.0184 14.6985 93.1 14.6985 93.1817 14.6985L93.1669 14.7022ZM80.8972 9.03521C85.372 9.03521 88.822 11.2931 90.1032 15.404C90.2958 16.0299 90.4349 16.6709 90.5191 17.3203H88.7514L70.9521 17.2571C71.9065 11.6644 75.6387 9.01664 80.8972 9.03521Z"
                                        fill="white"
                                    />
                                    <path d="M179.597 4.59003L185.387 4.60859L185.279 35.6915L179.501 35.6692L179.597 4.59003Z" fill="white" />
                                    <path d="M210.695 2.09454e-05C211.067 -0.0068695 211.437 0.0596167 211.783 0.195682C212.13 0.331747 212.446 0.534726 212.714 0.793023C212.982 1.05132 213.196 1.35988 213.345 1.70107C213.494 2.04226 213.574 2.40939 213.581 2.78151L213.532 17.4057C213.512 18.1569 213.194 18.8694 212.649 19.3867C212.104 19.904 211.376 20.1839 210.624 20.1649C210.252 20.1723 209.882 20.1061 209.536 19.9702C209.189 19.8344 208.873 19.6314 208.604 19.373C208.336 19.1146 208.122 18.8059 207.973 18.4644C207.825 18.123 207.745 17.7557 207.739 17.3834L207.791 2.75923C207.8 2.38726 207.883 2.02075 208.034 1.68067C208.184 1.3406 208.401 1.03363 208.671 0.777342C208.941 0.521055 209.258 0.32048 209.606 0.187097C209.953 0.0537142 210.323 -0.00985734 210.695 2.09454e-05Z" fill="white" />
                                    <path d="M195.428 22.4116C195.473 30.1656 199.079 36.4044 210.584 36.4416C222.088 36.4787 225.735 30.2733 225.835 22.5193L225.921 4.74967L220.128 4.7311L220.046 22.497C220.046 27.2021 217.55 31.0086 210.602 30.9826C203.654 30.9566 201.199 27.1353 201.214 22.4413L201.277 4.67169L195.469 4.64569L195.406 22.4116H195.428Z" fill="white" />
                                    <path d="M56.5804 3.49078C56.3465 3.49078 56.12 3.49078 55.8971 3.51307H55.6595C55.5109 3.51307 55.3624 3.54277 55.2176 3.56134L54.991 3.59105C49.636 4.43033 47.8423 9.44741 47.8423 9.44741L47.8609 4.10724L42.0045 4.08496L41.8969 35.1678H47.7532L47.7903 23.7893C47.8201 15.6194 50.3082 9.02034 56.495 9.04262C58.0635 9.06913 59.6072 9.43794 61.0182 10.1233L61.9689 4.67171C61.0368 4.10353 58.7603 3.49821 56.5804 3.49078Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_6_1158">
                                        <rect width="258.79" height="85.0453" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-7">
                            <h1>Check in 1 minuut of jouw woning geschikt is</h1>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
