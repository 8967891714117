const Invalid = () => {
    return (
        <div className="invalid">
            <h3>
                Helaas, met de huidige isolatiewaardes is het niet mogelijk om jouw hele woning te verwarmen met het greeniuz home heating system. Toch gas besparen? Vraag naar de mogelijkheden.
                <div className="meeting">
                    <a href="https://meet.greeniuz.nl/book/">Plan een adviesgesprek</a>
                </div>
            </h3>
        </div>
    );
};

export default Invalid;
