export const getApiError = (statusCode) => {
    switch (statusCode) {
        case 200:
            return 'Aanvraag is geslaagd.';
        case 400:
            return 'Jouw woning is niet geschikt of is (nog) niet geregistreerd. Neem contact op met een van onze adviseurs.';
        case 403:
            return 'Verboden.';
        case 401:
            return 'API key ontbreekt of bestaat niet.';
        case 422:
            return 'Aanvraag kan niet verwerkt worden, enkele inputs voldoen niet aan de documentatie.';
        case 429:
            return 'Je hebt de limiet van de quickscan bereikt.';
        case 500:
            return 'Service is niet beschikbaar - kijk op greeniuz.nl.';
        default:
            return 'Er is geen response omdat het geen valide request is op de API.';
    }
};
