export const roomHeight = 2.6;
export const targetTemp = 20;
export const heatingDays = 160;
export const heatingHours = 6;
export const extraRequiredWattage = 0.02;
export const roundOffPanelsPerRoom = 0.06;
export const panelWattage = 300;
export const panelCosts = 450;
export const domoticaPercentage = 0.5;
export const domoticaCosts = 100;
export const vat = 0.21;
// export const elecPrice = 0.4;
export const gasForHeating = 0.8;
// export const gas = 2.96;

export const floorInsideAdjacentTemp = 17;
export const floorOutsideAdjacentTemp = 3;
export const ceilingInsideAdjacentTemp = 17;
export const ceilingOutsideAdjacentTemp = -7;
export const wallsInsideAdjacentTemp = 17;
export const wallsOutsideAdjacentTemp = -7;
